<template>
    <div class="myCount_details">
        <div class="title">
            <p>
                <i class="iconfont icon-feiyongmingxi"></i>
                操作明细
            </p>
            <el-button
                @click="onClose()"
                size="mini"
                type="primary"
                icon="el-icon-arrow-left"
                style="background: #fff; color: #2370eb; border-color: #2370eb"
                >返回列表</el-button
            >
        </div>
        <div class="table_box">
            <div
                v-for="(data, index) in tableData"
                :key="data.id"
                style="margin-bottom: 12px"
            >
                <div class="parent_box">
                    <img src="@/img/waterfalls-h.png" alt="" />
                    <p>
                        <span style="margin-left: 5px">结算编号:</span
                        >{{ data.settleNo || '- -' }}
                    </p>
                    <p style="min-width: 135px">
                        <span>交易时间:</span
                        >{{
                            data.tradeTime ? data.tradeTime.slice(0, 10) : '- -'
                        }}
                    </p>
                    <p style="flex: 0.8">
                        <span>回款金额:</span>{{ data.paymentAmount || '- -' }}
                    </p>
                    <p style="min-width: 220px">
                        <span>客户名称:</span>
                        <el-tooltip
                            v-if="data.companyName.length > 14"
                            class="item"
                            effect="dark"
                            :content="data.companyName"
                            placement="top"
                        >
                            <em style="font-style: normal">{{
                                data.companyName
                                    ? data.companyName.slice(0, 14)
                                    : '- -' || '- -'
                            }}</em>
                        </el-tooltip>
                        <em style="font-style: normal" v-else>{{
                            data.companyName
                        }}</em>
                    </p>
                    <p style="min-width: 190px">
                        <span>合同编号:</span>{{ data.contractNo || '- -' }}
                    </p>
                    <p style="flex: 0.8">
                        <span>合同金额:</span>{{ data.contractAmount || '- -' }}
                    </p>
                    <p>
                        <span style="width: 30px">线索</span
                        ><el-select
                            v-model="data.lineType"
                            placeholder="请选择"
                            size="mini"
                            style="width: 60px; font-weight: 600"
                            @change="modify(data)"
                            :disabled="!isBtnP || father.status != 2"
                        >
                            <el-option label="是" :value="1"></el-option>
                            <el-option label="否" :value="2"></el-option>
                        </el-select>
                    </p>
                    <div style="min-width: 110px">
                        <span style="color: #333333; font-weight: 600"
                            >协作人</span
                        >
                        <p>
                            <span
                                class="label_del_box"
                                v-for="(
                                    item, idx
                                ) in data.billSettleSynergyBOList"
                                :key="item.id"
                                >{{ item.adminName }}
                                <span
                                    v-if="isBtnP && father.status == 2"
                                    class="cancel"
                                    @click="onDeleteLabel(index, idx)"
                                >
                                    <span class="el-icon-close"></span>
                                </span>
                            </span>
                        </p>
                        <el-button
                            v-if="
                                isBtnP &&
                                data.billSettleSynergyBOList.length == 0
                            "
                            :disabled="father.status != 2"
                            type="primary"
                            @click="onAddCL(data)"
                            style="
                                color: rgba(0, 0, 0, 0.45);
                                background: rgba(0, 0, 0, 0.02);
                                border-color: rgba(0, 0, 0, 0.15);
                                width: 25px;
                                height: 25px;
                                padding: 0;
                                font-size: 14px;
                                display: block;
                            "
                            class="el-icon-plus"
                        >
                        </el-button>
                    </div>

                    <!-- <img src="@/img/Fill_1.png" class="more_info" alt="" /> -->
                </div>
                <div v-if="data.billSettleInfoBOList" class="child_box">
                    <div
                        v-for="(item, i) in data.billSettleInfoBOList"
                        :key="item.id"
                    >
                        <span style="color: #2370eb">{{ i + 1 }}</span>
                        <p>
                            <span>产品名称:</span>
                            <em style="min-width: 80px">
                                {{
                                    item.productAttributeRelationBOList &&
                                    item.productAttributeRelationBOList.length >
                                        0
                                        ? item.productAttributeRelationBOList[0]
                                              .productName
                                        : '- -'
                                }}
                            </em>
                        </p>
                        <p>
                            <span>购买类型:</span>
                            <em>
                                {{ returnOrderType(item) }}
                            </em>
                        </p>
                        <p>
                            <span>用户数:</span>
                            <em>
                                {{
                                    item.productCategory != 5 &&
                                    item.productCategory != 6 &&
                                    item.productCategory != 8 &&
                                    item.productCategory != 9 &&
                                    item.productCategory != 12 &&
                                    item.productCategory != 3
                                        ? item.orderNumber
                                        : '- -'
                                }}
                            </em>
                        </p>
                        <p>
                            <span>年限:</span>
                            <em>
                                {{
                                    item.productCategory != 6 &&
                                    item.productCategory != 9 &&
                                    item.productCategory != 11 &&
                                    item.productType != 3
                                        ? item.orderTerm
                                        : '- -'
                                }}
                            </em>
                        </p>
                        <p>
                            <span>指导价:</span>
                            <em>
                                {{ item.guideAmount }}
                            </em>
                        </p>
                        <p>
                            <span>应收金额:</span>
                            <em>
                                {{ item.receivableAmount }}
                            </em>
                        </p>
                        <p>
                            <span>实收金额:</span>
                            <em>
                                {{ item.actualAmount }}
                            </em>
                        </p>
                        <p>
                            <span>业绩:</span>
                            <em>
                                {{ item.achievementAmount }}
                            </em>
                        </p>
                        <p>
                            <span>类型:</span>
                            <em v-if="data.lineType == 1"> 资源业绩 </em>
                            <em
                                v-else-if="
                                    item.productCategory == 1 ||
                                    item.productCategory == 2 ||
                                    item.productCategory == 4 ||
                                    item.productCategory == 7 ||
                                    item.productCategory == 10 ||
                                    item.productCategory == 14 ||
                                    item.productCategory == 12 ||
                                    item.productCategory == 5 ||
                                    item.productCategory == 8
                                "
                            >
                                {{
                                    item.orderType == 2 || item.orderType == 3
                                        ? '老客业绩'
                                        : '新购业绩'
                                }}
                            </em>
                            <em v-else>新购业绩</em>
                        </p>
                        <p>
                            <span>状态:</span>
                            <em>
                                {{
                                    item.confirmStatus == 1
                                        ? '待确认'
                                        : item.confirmStatus == 2
                                        ? '已确认'
                                        : item.confirmStatus == 3
                                        ? '已驳回'
                                        : item.confirmStatus == 4
                                        ? '已删除'
                                        : '- -'
                                }}
                            </em>
                        </p>
                        <p>
                            <span>反馈说明:</span>
                            <em style="min-width: 80px">
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="item.remark"
                                    placement="top"
                                >
                                    <em>
                                        {{ item.remark.slice(0, 4) || '- -' }}
                                    </em>
                                </el-tooltip>
                            </em>
                        </p>
                        <p v-if="item.confirmStatus == 1 && isBtnP">
                            <el-button
                                style="color: #2370eb"
                                type="text"
                                @click="onConfirm(item, data, 2)"
                                :disabled="father.status != 2"
                                >确认
                            </el-button>
                            <el-button
                                v-if="item.achievementAmount < 0"
                                style="color: #2370eb"
                                type="text"
                                @click="onDelete(item, data)"
                                :disabled="father.status != 2"
                                >删除</el-button
                            >
                            <el-button
                                style="color: #2370eb"
                                type="text"
                                @click="onFeedback(item, data)"
                                :disabled="father.status != 2"
                                >反馈</el-button
                            >
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
            <span class="bntP_box" v-if="isBtnP && father.status == 2">
                <el-button
                    round
                    type="primary"
                    style="
                        width: 100px;
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 20px;
                        margin-left: 0;
                    "
                    size="mini"
                    @click="onSubmit"
                    >确认账单</el-button
                ></span
            >
        </div>
        <!-- 添加协作人 -->
        <el-dialog
            :visible.sync="dialogClueAssist"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    添加协作人
                </div>
            </template>
            <div>
                <AddAssist ref="addassist" @close="handleClose" />
            </div>
        </el-dialog>
        <!-- 删除 -->
        <el-dialog
            :visible.sync="dialogDelete"
            width="560px"
            :before-close="handleDelete"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    删除
                </div>
            </template>
            <Delete ref="delete" @close="handleDelete" />
        </el-dialog>
        <!-- 反馈 -->
        <el-dialog
            :visible.sync="dialogFeedback"
            width="560px"
            :before-close="handleFeedback"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    反馈
                </div>
            </template>
            <Feedback ref="feedback" @close="handleFeedback" />
        </el-dialog>
        <!-- 发送 -->
        <el-dialog
            :visible.sync="dialogSendOut"
            width="560px"
            :before-close="handleSendOut"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    确认账单
                </div>
            </template>
            <SendOut ref="sendOut" @close="handleSendOut" />
        </el-dialog>
    </div>
</template>
<script>
import Delete from './delete.vue';
import AddAssist from './addAssist.vue';
import Feedback from './feedback.vue';
import SendOut from './sendOut.vue';
import { relationList, relationDelete } from '@/api/user/label.js';
import {
    billListGet,
    billSettleList,
    billSettleInfoUpdate,
    billSettleUpdate,
} from '@/api/count/business';
export default {
    components: {
        Delete,
        AddAssist,
        Feedback,
        SendOut,
    },
    data() {
        return {
            tableData: [],
            btnP: {},
            dialogDelete: false,
            dialogClueAssist: false,
            dialogFeedback: false,
            dialogSendOut: false,
            pagesize: 20,
            total: 0,
            currentPage: 1,
            isBtnP:
                this.$route.query.adminId == sessionStorage.getItem('adminId')
                    ? true
                    : false,
            father: {},
        };
    },
    mounted() {
        this.getFather();
        this.getData();
    },
    methods: {
        getFather() {
            let data = {
                param: {
                    id: this.$route.query.id,
                },
            };
            billListGet(data).then((res) => {
                console.log(res);
                this.father = res.data;
            });
        },
        getData() {
            let data = {
                param: {
                    billId: this.$route.query.id,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            billSettleList(data).then((res) => {
                console.log(res);
                for (let i in res.data.list) {
                    res.data.list[i].more = true;
                    if (res.data.list[i].lineType == 0) {
                        res.data.list[i].lineType = '';
                    }
                }
                this.tableData = res.data.list;
                this.total = res.data.total;
            });
        },
        // 线索
        modify(row) {
            let data = {
                param: {
                    id: row.id,
                    waterId: row.waterId,
                    lineType: row.lineType,
                    billId: this.$route.query.id,
                    billSettleConfirmSynergyBOList: [
                        {
                            adminId:
                                row.billSettleSynergyBOList &&
                                row.billSettleSynergyBOList.length > 0
                                    ? row.billSettleSynergyBOList[0].adminId
                                    : '',
                        },
                    ],
                },
            };
            billSettleUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.getData();
                }
            });
        },
        returnOrderType(data) {
            let a = '- -';
            data.productAttributeRelationBOList.forEach((item) => {
                if (item.attributeColumnName == 'productType') {
                    item.productAttributeValueBOList &&
                    item.productAttributeValueBOList.length > 0
                        ? item.productAttributeValueBOList.forEach((itm) => {
                              if (data.orderType == itm.attributeValue) {
                                  a = itm.attributeValueName;
                              }
                          })
                        : '- -';
                }
            });
            return a;
        },
        // 协作
        onAddCL(row) {
            this.dialogClueAssist = true;
            setTimeout(() => {
                this.$refs.addassist.getAssist(row);
            });
        },
        handleClose() {
            this.dialogClueAssist = false;
            this.getData();
            this.getFather();
        },
        // 删除
        onDelete(row, father) {
            this.dialogDelete = true;
            setTimeout(() => {
                this.$refs.delete.getData(row, father);
            });
        },
        handleDelete() {
            this.getData();
            this.getFather();
            this.dialogDelete = false;
        },
        // 反馈
        onFeedback(row, data) {
            this.dialogFeedback = true;
            setTimeout(() => {
                this.$refs.feedback.getData(row, data);
            });
        },
        handleFeedback() {
            this.getData();
            this.getFather();
            this.dialogFeedback = false;
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.currentPage = 1;
            this.getData();
        },

        // 确认
        onConfirm(row, father, i) {
            let data = {
                param: {
                    id: row.id,
                    orderId: row.orderId,
                    confirmStatus: i,
                    formerAmount: row.formerAmount,
                    actualAmount: row.actualAmount,
                    waterId: father.waterId,
                    billId: this.$route.query.id,
                },
            };
            billSettleInfoUpdate(data).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.getData();
                }
            });
        },
        onClose() {
            this.$router.push('/myCount');
        },
        onSubmit() {
            let data = {
                param: {
                    billId: this.$route.query.id,
                },
                pageNum: 0,
                pageSize: 0,
            };
            billSettleList(data).then((res) => {
                let ststus = 3;
                for (let i in res.data.list) {
                    if (
                        !res.data.list[i].lineType ||
                        res.data.list[i].lineType == 0
                    ) {
                        return this.$message.error('请选择线索');
                    }
                    for (let j in res.data.list[i].billSettleInfoBOList) {
                        if (
                            res.data.list[i].billSettleInfoBOList[j]
                                .confirmStatus == 1
                        ) {
                            return this.$message.error('请确认订单');
                        }
                        if (
                            res.data.list[i].billSettleInfoBOList[j]
                                .confirmStatus == 3
                        ) {
                            ststus = 4;
                            break;
                        }
                    }
                }
                this.dialogSendOut = true;
                setTimeout(() => {
                    this.$refs.sendOut.getData(ststus);
                });
            });
        },
        // 移除标签
        onDeleteLabel(index, idx) {
            this.tableData[index].billSettleSynergyBOList.splice(idx, 1);
            let data = {
                param: {
                    id: this.tableData[index].id,
                    waterId: this.tableData[index].waterId,
                    lineType: this.tableData[index].lineType,
                    billSettleConfirmSynergyBOList: [
                        {
                            adminId: '',
                        },
                    ],
                    billId: this.$route.query.id,
                },
            };
            billSettleUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                }
                this.handleSendOut();
            });
        },
        handleSendOut() {
            this.dialogSendOut = false;
            this.getData();
            this.getFather();
        },
    },
};
</script>
<style lang="scss" scoped>
.myCount_details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title {
        padding: 12px;
        background: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        p {
            color: #333333;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 600;
            .iconfont {
                color: #2370eb;
                font-size: 25px;
                margin-right: 5px;
            }
        }
        button {
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            font-size: 12px;
            color: #2370eb;
        }
    }
    .table_box {
        flex: 1;
        margin-top: 13px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .parent_box {
        background-color: #fafafa;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        flex-wrap: nowrap;
        position: relative;
        p {
            flex: 1;
            font-size: 12px;
            font-weight: 600;
            color: #333;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            white-space: nowrap;
            span {
                color: #333333;
                font-weight: normal;
                margin: 0 0 0 6px;
                font-weight: 600;
                display: block;
                width: 55px;
            }
        }
        > div {
            flex: 1;
            font-size: 12px;
            font-weight: 600;
            color: #333;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            white-space: nowrap;
            > p {
            }
            > span {
                color: #999;
                font-weight: normal;
                margin: 0 0 0 6px;
                display: block;
                width: 43px;
            }
        }
        .more_info {
            position: absolute;
            bottom: -8px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .label_del_box {
        color: #2370eb;
        border: 1px solid #2370eb;
        font-weight: normal;
        padding: 4px 8px;
        position: relative;
        text-align: center;
    }
    .child_box {
        > div {
            display: flex;
            align-items: center;
            height: 50px;
            padding: 0 20px;
            font-size: 12px;
            background-color: #fff;
            max-width: 120%;
            overflow-x: auto;
            p {
                flex: 1;
                color: #333;
                white-space: nowrap;
                display: flex;
                span {
                    font-weight: normal;
                    color: #666666;
                    white-space: nowrap;
                    margin: 0 8px;
                }
                em {
                    flex: 1;
                    white-space: nowrap;
                    font-style: normal;
                    color: #666666;
                }
            }
        }
    }
    .empty {
        margin-top: 10px;
    }
}
.page-box {
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.bntP_box {
    position: absolute;
    right: 12px;
}
.assist {
    display: flex;
    p {
        overflow: hidden;
        flex: 1.5;
    }
    span {
        margin-right: 8px;
    }
}
/deep/ .el-table th {
    font-size: 12px !important;
    font-weight: normal !important;
}
.el-table {
    font-size: 12px !important;
}
/deep/ .el-table th.el-table__cell > .cell {
    font-weight: normal !important;
}
.cancel {
    cursor: pointer;
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    right: -57px;
    bottom: -30px;
    border-top: 10px solid rgba(255, 255, 255, 0);
    border-right: 10px solid rgba(255, 255, 255, 0);
    border-bottom: 10px solid rgba(255, 255, 255, 0);
    border-left: 10px solid #2370eb;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}
.cancel .el-icon-close {
    color: #fff !important;
    position: absolute;
    font-size: 8px;
    left: -40px;
    top: -7px;
    font-weight: 400 !important;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}
</style>

<style>
.myCount_details .child_box .el-table__row {
    /* background: #fafafa !important; */
    border: none;
}
.myCount_details .el-table td.el-table__cell {
    border: none !important;
}
.myCount_details .el-table::before {
    height: 0 !important;
}
/* .myCount_details {
    display: none !important;
} */
</style>
