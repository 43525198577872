<template>
    <div>
        <div>
            <div class="dialog_info">
                <el-form ref="form" label-width="80px">
                    <el-form-item label="反馈">
                        <el-input
                            type="textarea"
                            v-model="textarea"
                            style="width: 300px"
                            :rows="4"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="but">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                        margin-left: auto;
                    "
                    @click="onClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 30px;
                    "
                    size="mini"
                    @click="onSubmit"
                    >确定</el-button
                >
            </div>
        </div>
    </div>
</template>
<script>
import { billSettleInfoUpdate } from '@/api/count/business';
export default {
    data() {
        return {
            textarea: '',
            row: {},
            father: '',
        };
    },
    methods: {
        getData(data, father) {
            this.row = data;
            this.father = father;
            this.textarea = data.remark;
        },
        onSubmit() {
            if (!this.textarea) {
                return this.$message.error('请输入反馈内容');
            }
            let data = {
                param: {
                    id: this.row.id,
                    orderId: this.row.orderId,
                    confirmStatus: 3,
                    formerAmount: this.row.formerAmount,
                    actualAmount: this.row.actualAmount,
                    waterId: this.father.waterId,
                    billId: this.$route.query.id,
                    remark: this.textarea,
                },
            };
            billSettleInfoUpdate(data).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.textarea = '';
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.dialog_info {
    padding: 20px 50px 150px 50px;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
