<template>
    <div>
        <div class="dialog_info">
            <p>
                <i
                    class="el-icon-warning-outline"
                    style="color: #f5a623; font-size: 50px; margin-bottom: 15px"
                ></i>
                <br />
                即将删除当前账单，删除的账单将记录到下一次结算，是否确认？
            </p>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666; margin-left: auto"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff; margin-right: 30px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { billSettleInfoUpdate } from '@/api/count/business';
export default {
    data() {
        return {
            row: {},
            data: {},
        };
    },
    methods: {
        getData(row, data) {
            this.row = row;
            this.data = data;
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.row.id,
                    orderId: this.row.orderId,
                    confirmStatus: 4,
                    formerAmount: this.row.formerAmount,
                    actualAmount: this.row.actualAmount,
                    waterId: this.data.waterId,
                    billId: this.$route.query.id,
                },
            };
            billSettleInfoUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}

.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
.dialog_info {
    width: 80%;
    text-align: center;
    margin: 25px auto 150px;
    font-size: 14px;
    color: #333333;
    p {
        span {
            // color: #666;
        }
    }
    p + p {
        margin-top: 16px;
    }
    .icon-zhuyi {
        display: block;
        margin: 0 auto 45px;
        font-size: 40px;
        color: #feb516;
    }
}
</style>
